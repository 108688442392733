body {
  overflow-x: hidden;
}
$MEDIUM_SCREEN: 991px;
$LARGE_SCREEN: 1200px;

.floor-planner {
  padding: 0 !important;
}
.btn {
  outline: none;
  &:disabled {
    border: 0;
  }
}
.canvas-container {
  @media screen and (min-width: $MEDIUM_SCREEN) {
    display: flex;
  }
  .canvas-wrapper {
    width: 100%;
    @media screen and (min-width: $MEDIUM_SCREEN) {
      width: 70%;
    }
    .canvas-container {
      height: 65vh !important;

      p {
        width: 80%;
        text-align: center;
        margin-inline: auto;
        @media screen and (min-width: 1200px) {
          font-size: 1.2rem;
        }
      }
    }
  }
  .extra-info {
    // width: 35%;
    position: fixed;
    bottom: 0;
    left: -15px;
    z-index: 200;
    width: 100%;
    border: 1px solid #cccc;
    border-radius: 8px 8px 0 0;
    box-shadow: 1px 1px 20px #cccc;
    margin-inline: 1rem;

    @media screen and (min-width: $MEDIUM_SCREEN) {
      top: 20%;
      left: 70%;
      width: 30%;
      border: 0;
      border-radius: 0;
      margin-inline: 0;
    }
    @media screen and (min-width: $LARGE_SCREEN) {
      top: 26%;
    }
    @media screen and (min-width: 1400px) {
      top: 12%;
      left: unset;
      right: 0;
      width: 21%;
    }
  }
}
.shapes-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border: 1px solid #cccc;
  padding-bottom: 0 !important;
  padding-top: 7px !important;

  @media screen and (min-width: 991px) {
    left: 256px;
  }
  @media screen and (min-width: 1400px) {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
.text-capitalize {
  text-transform: capitalize;
}
.select-content {
  max-height: 190px;
  overflow-y: scroll;
}

.bg-light {
  background: #f4f4f4 !important;
}

ul {
  padding-inline: 0 !important;
  li {
    list-style: none;
  }
}
