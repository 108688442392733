// @import "~bootstrap/scss/bootstrap";
// customization style
@import "./theme";
@import "../../node_modules/@coreui/coreui/scss/coreui";
@import "./utilities";
@import "./sidebar";

.brand-photo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
}

.table-responsive {
  max-width: 100%;
  // width: 93%;
}
// SEARCH form
.search-form {
  cursor: text;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  width: 280px;
  transition: 300ms;
  margin-right: 1.2rem;
  border: 1px solid $gray-100;

  .input-field {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    width: 200px;
    &:focus-within {
      width: 100%;
      justify-content: flex-start;
    }
  }
  input {
    font-size: 16px;
    border: none;
    outline: none;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 91px;
    transition: 300ms;
    margin: 0px 10px;
    &:focus {
      width: 100%;
    }
  }

  &:hover,
  &:focus-within {
    border: 1px solid $gray-100;
  }
}

.bg-header {
  background-image: url("../components/staticsLogos/header.png");
  background-size: cover;
}
.cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8rem;
}

.card-content {
  position: absolute;
  top: 25%;
  left: 15%;
}
.stats-content {
  position: absolute;
  top: 20%;
  left: 3%;
}

// OPENING HOURS TAGS
.tag-wrapper.ReactTags__tag {
  background: $danger-50;
  color: #ffff;
  border-radius: 20px;
  padding: 0.4rem;
  display: flex;
  align-items: center;
  gap: 0.3rem;

  button {
    border: 0;
    outline: 0;
    background-color: $danger;
    color: $light;
    border-radius: 100%;
    color: $light;
    width: 25px;
    height: 25px;
    margin-left: 5px;
  }
}
.ReactTags__selected {
  display: flex;
  gap: 0.3rem;
  // flex-wrap: wrap;
  overflow-x: auto;
}

.venez-meal-swiper .swiper-wrapper > .swiper-slide {
  max-width: 10rem;
}

// SIDEBAR
.sidebar-nav .nav-link {
  // padding: 0.65rem;
  padding: 0;
}

.sidebar-nav .sidbar-link {
  padding: 0.65rem;
}
.layout-content {
  @media screen and (min-width: 991px) {
    width: 90%;
  }
}

.place-dropdown {
  position: absolute;
  // top: 80px;
  right: 26px;
  z-index: 100;
  & > div:not(:nth-child(1)) {
    margin-left: -4px;
  }
  & > * {
    border-radius: 4px;
    padding: 10px;
    &:hover {
      background-color: rgba(244, 244, 244, 0.4);
    }
  }
}
.scan-alternative {
  position: relative;

  &:before {
    width: 60px;
    height: 1px;
    background-color: #7a7878cc;
    position: absolute;
    top: 50%;
    left: 5px;
    content: "";
  }
  &:after {
    width: 60px;
    height: 1px;
    background-color: #7a7878cc;
    position: absolute;
    top: 50%;
    right: 5px;
    content: "";
  }
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

// .contract-container {
//   max-width: 900px;
//   margin: 0 auto;
//   text-align: justify;
//   // position: relative;
//   // padding-block: 3rem;
//   // padding-inline: 1.3rem;
//   // @media screen and (min-width: 1100px) {
//   //   max-width: 65%;
//   //   height: 650px;
//   //   overflow-y: scroll;
//   //   padding-inline: 3rem;
//   // }
// }

// .contract-btns-container {
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   height: 60px;
//   background-color: white;
// }

.contract-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 85px;
  overflow-y: auto;
}

.contract-content-container {
  max-width: 900px;
  margin: 0 auto;
  text-align: justify;
  padding: 40px;
}

.contract-btns-container {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 85px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.5);
}

.contract-btns-wrapper {
  max-width: 900px;
  margin: 0 auto;
  padding-right: 40px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.contract-action-btn {
  flex: 1;
}

.contract-action-btn:not(:last-child) {
  margin-right: 20px;
}

.openingHour-type-wrapper {
  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.organize__offers_wrapper {
  display: grid;
  grid-template-columns: 1;
  gap: 1rem;

  & > * {
    background: #ffff;
    padding: 0.7rem;
    border-radius: 1rem;
  }
  @media screen and (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }
}
.organize__offer__description {
  width: 100%;
  @media screen and (min-width: 640px) {
    width: 75%;
  }
}
.organize__offer_variants_wrapper {
  // display: grid;
  // grid-template-columns: 1;
  // gap: 1rem;

  & > * {
    background: #ffff;
    padding: 0.7rem;
    border-radius: 1rem;
  }
  // @media screen and (min-width: 640px) {
  //   grid-template-columns: repeat(2, 1fr);
  // }
}

// PLACE ADMIN
.place-admin-header {
  display: flex;
  gap: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-x: scroll;
  padding-block: 1rem;
  cursor: grab;
  &::-webkit-scrollbar {
    display: none;
  }
  a,
  button {
    color: black !important;
    min-width: fit-content;
    &:hover {
      background: $light;
    }
  }
}

.converation_wrapper {
  .conversation_sidebar {
    @media screen and (min-width: 991px) {
      flex: 35% 0;
    }
  }
  .conversation_item {
    padding: 8px;
    &:hover,
    .selected_conversation {
      background: #f4f4f4;
      border-radius: 8px;
    }
  }
}
.chat-ui-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    font-size: 1rem;
  }
}

.chat-ui {
  // height: 100%;
  height: 80vh;
  @media screen and (min-width: 1300px) {
    max-width: 75%;
  }
}
.chat-bubble {
  max-width: 80%;
  @media screen and (min-width: 768px) {
    min-width: 20%;
  }
}
.chats-wrapper {
  overflow-y: scroll;
  max-height: 80%;
}

.ride-table-info-wrapper {
  max-height: 80vh;
  overflow-y: auto;
}

.chats-wrapper-full {
  overflow-y: scroll;
  max-height: 100%;
  
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.transactions-filters {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  @media screen and (min-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .filters {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    @media screen and (min-width: 991px) {
      flex-direction: row;
      align-items: center;
    }
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
