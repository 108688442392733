// background classes
.bg-gradient-primary {
  background: linear-gradient(
    92.01deg,
    $danger 1.63%,
    $warning 100%
  ) !important;
}
.text-gradient-primary {
  background: linear-gradient(92.01deg, #ff7722 1.63%, #ffb800 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sidebar {
  background: #fff !important;
  width: 100px;
  border-right: 1px solid rgb(224, 224, 224);
}

.w-fit-content {
  min-width: fit-content !important;
}

.nav-menu {
  li {
    &:hover {
      background: rgba(255, 119, 34, 0.3);
      border-radius: 8px;
    }
  }
  .nav-title {
    &:hover {
      background: #fff !important;
      border-radius: 0px;
    }
  }
}
.HeaderMenu {
  border-bottom: 1px solid rgb(224, 224, 224);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 50;
}

.bg-gray {
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
}
// SHADOW CLASSES

* {
  font-family: "Poppins", sans-serif !important;
}
body {
  background-color: #fafafa !important;
}
.shadow-primary {
  box-shadow: 0px 6px 16px rgba(255, 119, 34, 0.2);
}
.shadow-secondary {
  box-shadow: 0px 6px 16px rgba(34, 170, 255, 0.2);
}

// border & outline
.outline-none {
  outline: none;
}

.btn-outline-danger:hover {
  color: $light !important;
  svg,
  path {
    fill: $light;
  }
}

// text class
.text-sm {
  font-size: 14px;
}

.h-full {
  height: 100%;
}
.h-90 {
  height: 90%;
}
.h-80 {
  height: 80%;
}

.w-full {
  width: 100%;
}
.w-90 {
  width: 95%;
}
.w-85 {
  width: 85% !important;
}
.w-65 {
  width: 65% !important;
}
.w-75 {
  width: 75% !important;
}
.w-80 {
  width: 80% !important;
}
.w-fit {
  width: fit-content !important;
}

.w-4 {
  width: 20px;
}
.h-4 {
  height: 20px;
}
.w-5 {
  width: 25px;
}
.h-5 {
  height: 25px;
}
.text-auto {
  text-align: center;
}
.text-xs {
  font-size: 0.6rem;
}
.text-sm {
  font-size: 0.8rem;
}
.text-md {
  font-size: 1rem;
}
.text-lg {
  font-size: 1.5rem;
}
.rounded-xs {
  border-radius: 4px;
}
.rounded-sm {
  border-radius: 8px;
}
.rounded-md {
  border-radius: 15px;
}
.rounded-tr-md {
  border-top-right-radius: 15px;
}
.rounded-tl-md {
  border-top-left-radius: 15px;
}
.rounded-bl-md {
  border-bottom-left-radius: 15px;
}
.rounded-br-md {
  border-bottom-right-radius: 15px;
}
.rounded-full {
  border-radius: 999px !important;
}

.bg-primary-shade {
  background: rgba(255, 119, 34, 0.3);
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-y-auto {
  overflow-y: auto;
}
.line-clamp-1 {
  text-overflow: ellipsis;

  overflow: hidden;
  white-space: nowrap;
}

.border-success {
  border-color: $success-base !important;
}
.bg-success-shade {
  background-color: $success-50;
}

.outline-none {
  outline: none;
}

$lightGray: #f2f3f7;
.text-light {
  color: #7a7878cc;
}

button:disabled {
  opacity: 0.85;
}
.pointer {
  cursor: pointer;
}

input {
  position: relative;
}

.hover-light:hover {
  background: $lightGray !important;
}
.form-control,
.form-select {
  padding: 0.475rem 0.75rem !important;
  &:focus {
    transition: all ease-in-out 3ms;
    outline: 0;
    outline: 0;
    box-shadow: 0 0 0 0.18rem rgba(179, 179, 179, 0.25);
    border: 0;
  }
}
.custom-input, //delete 
select,.ReactTags__tagInputField {
  display: block;
  width: 100%;
  padding: 0.475rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--cui-input-color, rgba(44, 56, 74, 0.95));
  background-color: var(--cui-input-bg, #fff);
  background-clip: padding-box;
  border: 1px solid var(--cui-input-border-color, #b1b7c1);
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 0.27rem;
  outline: none;
}

input:required::before,
select:required::before {
  position: absolute;
  top: -15px;
  right: 0;
  content: "*";
  color: $danger;
}

.flex {
  flex: 1;
}
.reset-button {
  background-color: transparent;
  outline: none;
  border: none;
}
.welcome-banner {
  border-radius: 0 0 0 12px;
}

.fill-danger {
  fill: $danger;
}
.fill-success {
  fill: $success;
}
.fill-red {
  fill: $red;
}
.fill-primary {
  fill: $primary;
}
.fill-black {
  fill: #200e32;
}
.fill-white {
  fill: #fff;
}
.fill-attention {
  fill: #ff6900;
}
.text-attention {
  color: #ff6900;
}
.mx-500 {
  max-width: 500px;
}
.cursor-pointer {
  cursor: pointer;
}
.object-cover {
  object-fit: cover;
  max-width: 100%;
}

.delete-add button,
.delete-add a {
  white-space: pre;
}
.delete-add:nth-child(3) {
  border-top-left-radius: 0px !important;
}

.gallery-delete-btn {
  position: absolute;
  right: -10px;
  background: white;
  border-radius: 50%;
  display: flex;
  padding: 0.3rem;
  margin: 0 auto;
  border: 2px solid black;
}
.select-btn-active {
  padding: 0.15rem;
  border-radius: 4px;
  cursor: pointer;
  width: 7rem;
  height: 2.25rem;
}

.select-btn-notactive {
  padding: 0.15rem;
  border-radius: 4px;
  cursor: not-allowed;
  width: 7rem;
  height: 2.25rem;
}

.text-pre {
  white-space: pre;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.logout {
  color: #ff4d4f;
}
td {
  white-space: nowrap;
}

.carte {
  max-width: 450px;
}

.cart {
  width: 100%;
  // justify-content: center;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  width: 70%;
  margin: auto;
  padding: 2rem 0;
}

.table-container {
  width: calc(100% - 16rem) !important;
}
@media screen and (max-width: 994px) {
  .table-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 576px) {
  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }
}

.gallery-item {
  position: relative;
  border-radius: 10px;
  height: 250px;
  display: flex;
  flex-direction: column;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.2s ease-in-out;
  border-radius: 10px;
  cursor: cell;
}

.popup-modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.popup-image {
  max-width: 90vw;
  max-height: 90vh;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}

@media screen and (max-width: 1290px) {
  .before-table {
    flex-wrap: wrap;
    width: 100%;
    // justify-content: center !important;
  }

  .before-table :last-child {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
  }

  .before-table :nth-child(1) {
    order: 1;
  }
  .before-table :nth-child(2) {
    order: 3;
  }
  .before-table :nth-child(3) {
    order: 0;
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .delete-add {
    justify-content: center;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
  }
  .popup-image {
    max-width: 80vw;
    max-height: 80vh;
  }

  table {
    max-width: 100%;
    overflow-x: auto;
    display: block;
  }

  .search-form {
    margin: 2rem auto !important;
  }
}

@media screen and (max-width: 576px) {
  .popup-image {
    max-width: 90vw;
    max-height: 90vh;
  }
}

.delete-button {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  height: 2.2rem;
  width: 2.2rem;
  border: 0;
  margin: auto;
}

.delete-btn {
  width: 1.5rem;
  height: 1.5rem;
}

.checkbox-pic {
  position: absolute;
  background-color: white;
  margin: 0rem;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 10%;
  border-bottom-left-radius: 0;
}

.form-check-input:checked {
  background-color: #ffb800 !important;
  border-color: #ffb800 !important;
}

.form-check-input:focus {
  border-color: #ffb800;
  outline: 0;
  box-shadow: 0 0 0 0.1rem #ffb800;
}

.form-control:invalid,
.form-control.is-invalid {
  border-color: #b1b7c1 !important;
}
.form-select:invalid,
.form-select.is-invalid {
  border-color: #b1b7c1 !important;
}

.invalid-feedback {
  font-size: 0.7rem !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.invalid-feedback {
  display: none !important;
}
.custom-input:focus-within ~ .invalid-feedback {
  display: block !important;
}

.was-validated .form-control:valid:placeholder-shown {
  background-image: none;
  border-color: #b1b7c1 !important;
}

.daytime-wrapper {
  z-index: 80;
  background: linear-gradient(0deg, #ffffff, #ffffff), #fefbff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 200px;
  .row > * {
    height: 232px;
    width: 30%;
  }
}

.custom-icon {
  font-size: 8rem; 
  margin-top: 30px; 
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

@media screen and (min-width: 1500px) {
  .booking-search-wrapper {
    width: 50%;
  }
}
