$primary: #22aaff;
$danger: #ff7722;
$warning: #ffb800;
$red: #ff0000;
$success: #40ae40;
$purpele: #5F50FD;

$theme-colors: (
  "primary": $primary,
  "danger": $danger,
  "warning": $warning,
  "red": $red,
  "success": $success,
  "purple": $purpele,
);
