.sidebar {
  position: sticky;
  top: 45px;
  left: 0;
  height: 100vh;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;

  .accordion-button {
    &:not(.collapsed) {
      background: transparent;
      color: $danger;
      &::after {
        background-image: var(--cui-accordion-btn-icon);
      }
    }
  }

  .nav-item {
    padding: 12px 8px;
  }
}
